import _ from 'lodash';

import network from 'services';

import { alertToast } from 'config/toast';

const versionsError = e => {
  alertToast('Failed to fetch database version data', 'alert');
  return e;
};

const getById = (id) => network
  .get(`/versions/${id}`)
  .catch(versionsError);

const getAnnouncementById = (id) => network
  .get(`/announcements/${id}`)
  .catch(versionsError);

const createAnnouncement = (params) => network
  .post('/announcements', params)
  .then(result => {
    alertToast('Announcement created with success!', 'success');
    return result;
  })
  .catch(versionsError);

const editAnnouncement = (id, params) => network
  .put(`/announcements/${id}`, params)
  .then(() => alertToast('Announcement updated with success!', 'success'))
  .catch(versionsError);

const deleteAnnouncement = (id) => network
  .delete(`/announcements/${id}`)
  .then(() => alertToast('Announcement deleted with success!', 'success'))
  .catch(versionsError);

const getAll = (conf, company) => {
  const config = _.clone(conf);

  if (!_.has(config, 'params.owner')) {
    _.set(config, 'params.owner', 'none');
  }

  return network
    .get('/versions', config)
    .then(result => ({
      versions: _.filter(result['hydra:member'], v => {
        if (v.public) return v.public;
        if (v.owner) return v.owner === company || v.owner['@id'] === company;
        return v.public;
      }),
      nextPage: result['hydra:view'] && result['hydra:view']['hydra:next'],
    }))
    .catch(versionsError);
};

const getNextPageVersions = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(result => ({
    versions: result['hydra:member'],
    nextPage: result['hydra:view'] && result['hydra:view']['hydra:next'],
  }))
  .catch(versionsError);

const allAnnouncements = (params) => network
  .get('/announcements', { params })
  .then(result => ({
    versions: result['hydra:member'],
    nextPage: result['hydra:view'] && result['hydra:view']['hydra:next'],
  }))
  .catch(versionsError);

const getNextPageAnnouncements = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(result => ({
    versions: result['hydra:member'],
    nextPage: result['hydra:view'] && result['hydra:view']['hydra:next'],
  }))
  .catch(versionsError);

const getVersion = (companyId) => network
  .get('/versions', { params: { owner: 'none' }, cache: true })
  .then(({ 'hydra:member': versions }) => [
    _.find(versions, 'public'),
    _.find(versions, v => v.owner === companyId),
  ])
  .catch(versionsError);

export default {
  all: getAll,
  get: getVersion,
  getNextPageVersions,
  getById,
  allAnnouncements,
  getNextPageAnnouncements,
  getAnnouncementById,
  createAnnouncement,
  editAnnouncement,
  deleteAnnouncement,
};
